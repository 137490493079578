import { KudosContributor } from "./../../../@types/KudosContributor";
import { deleteReq, getReq, postReq } from "../ApiHelpers";

export interface IKudosDetails {
  tokenId: number;
  headline: string;
  description: string;
  startDateTimestamp: number;
  endDateTimestamp: number;
  links: string[];
  communityId: string;
  communityDisplayId: string;
  communityName: string;
  contributors: Array<KudosContributor>;
  createdByAddress: KudosContributor;
  createdAtTimestamp: number;
  imageUrl: string;
  generatedImageUrl?: string;
  claimers: Array<KudosContributor>;
  endorsers: Array<IKudosEndorser>;
  claimabilityAttributes: KudosClaimabilityAttributes;
  previewAssetUrl?: string;
  uniqId: string;
  totalCount?: number;
}

export interface IKudosEndorser {
  id: string;
  username: string;
}

export interface KudosClaimabilityAttributes {
  expirationTimestamp: string;
  isAllowlistRequired: boolean;
  isSignatureRequired: boolean;
  remainingClaimCount: number;
  totalClaimCount: number;
}

const SUPPORTED_TOKEN_ID_CLAIM_LINKS = new Set<string>([
  "1832",
  "1833",
  "1834",
  "1835",
  "1836",
]);

export const getKudosDetailsByUniqId = async (
  id: string,
  abortSignal?: AbortSignal,
): Promise<IKudosDetails> => {
  // Allow querying by tokenId for sake company tokens so that QR codes have backwards compatibility
  if (SUPPORTED_TOKEN_ID_CLAIM_LINKS.has(id)) {
    return getReq(`/token/${id}`, { signal: abortSignal }, false);
  }
  return getReq(`/token?uniqId=${id}`, { signal: abortSignal }, false);
};

export const getKudosDetails = async (
  id: string,
  abortSignal?: AbortSignal,
): Promise<IKudosDetails> => {
  return getReq(`/token/${id}`, { signal: abortSignal }, false);
};

export const getKudosOwners = async (
  tokenId: string,
  offset: number = 0,
  limit: number = defaultPageLimit,
  walletAddress?: string,
): Promise<any> => {
  let uri = `/token/${tokenId}/owners?limit=${limit}&offset=${offset}`;
  if (walletAddress) {
    uri = `/token/${tokenId}/owners?limit=${limit}&offset=${offset}&address=${walletAddress}`;
  }
  return getReq(uri, undefined, false);
};

export const getKudosContributors = async (
  tokenId: string,
  offset: number = 0,
  limit: number = defaultPageLimit,
  accountUniqId?: string,
): Promise<any> => {
  let uri = `/token/${tokenId}/contributors?limit=${limit}&offset=${offset}`;
  if (accountUniqId) {
    uri = `/token/${tokenId}/contributors?limit=${limit}&offset=${offset}&accountUniqId=${accountUniqId}`;
  }
  return getReq(uri, undefined, false);
};

export const getKudosEndorsers = async (tokenId: string): Promise<any> => {
  return getReq(`/token/${tokenId}/endorsers`, undefined, false);
};

export interface IEndorsement {
  kudosTokenId: number;
  endorserAddress: string;
}

export const addEndorsement = async (
  tokenId: string,
): Promise<IEndorsement> => {
  return postReq(`/token/${tokenId}/endorsements`, null, undefined, true);
};

export const removeEndorsement = async (
  tokenId: string,
): Promise<IEndorsement> => {
  return deleteReq(`/token/${tokenId}/endorsements`, undefined, true);
};

export const getDraftKudos = async (
  draftId: string,
): Promise<IKudosDetails> => {
  return getReq(`/drafts/${draftId}`, undefined, false);
};

const defaultPageLimit = 50;
