import { XIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";
import Button from "../../components/Button/Button";
import {
  TextH3,
  TextSecondary,
} from "../../components/DesignSystem/DesignSystemText";

interface Props {
  title: string;
  helperText?: string;
  onClose: () => void;
  children: ReactNode;
  helperLink?: string;
}

const AccountModalBase = ({
  title,
  helperText,
  onClose,
  children,
  helperLink,
}: Props) => {
  return (
    <>
      <div className="bg-white relative px-8 py-7 rounded-md">
        <TextH3 className="text-center">{title}</TextH3>
        <Button
          theme="custom"
          className="text-[#898989] absolute top-[28rem] right-[28rem]"
          onClick={onClose}
        >
          <XIcon width="30rem" type="button" height="30rem" />
        </Button>

        {children}

        <TextSecondary className="text-center">
          <a href={helperLink} target="_blank">
            {helperText}
          </a>
        </TextSecondary>
      </div>
    </>
  );
};

AccountModalBase.defaultProps = {
  helperLink: "https://metamask.io/download/",
};

export default AccountModalBase;
