import { useRouter } from "next/router";
import { getClaimableKudos, KudosToken } from "../../services/apis";
import { useLoginScreen } from "../../views/Account/LoginScreenState";
import DesignSystemAvatar from "../DesignSystem/DesignSystemAvatar";
import { TextBody } from "../DesignSystem/DesignSystemText";
import HeaderMenu, { HeaderMenuItemProps } from "./HeaderMenu";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import { useWallet } from "../../services/wallet/useWallet";

const HeaderAuthMenu = () => {
  const {
    disconnect,
    avatarUrl,
    addresses,
    username,
    displayName,
    isLoadingAuth,
    isLoggedIn,
    claimable,
  } = useWallet();

  const router = useRouter();
  const loginScreen = useLoginScreen();

  const navigateToProfile = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    const profile = username || addresses[0].address;
    router.push(`/profile/${profile}`, undefined, {
      shallow: true,
    });
  };

  const navigateToSettings = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    router.push("/settings");
  };

  const goToClaim = (e: React.MouseEvent) => {
    router.push(`/profile/${username}?tab=Claimable`, undefined, {
      shallow: true,
    });
  };

  const handleLogin = (e: any) => {
    loginScreen.open();
    e.preventDefault();
  };

  const handleLogout = async (e: React.MouseEvent) => {
    if (confirm("Sign out?")) {
      await disconnect();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const headerMenuItems: Array<HeaderMenuItemProps> = [
    {
      icon: "profile",
      label: "Profile",
      onClick: navigateToProfile,
    },
    {
      icon: "settings",
      label: "Edit Profile",
      onClick: navigateToSettings,
    },
    // @ts-ignore
    claimable && claimable?.length > 0
      ? {
          icon: "claim",
          label: "Claim Kudos",
          onClick: goToClaim,
        }
      : undefined,
    {
      icon: "logout",
      label: "Logout",
      onClick: handleLogout,
    },
  ];

  return (
    <>
      {isLoggedIn ? (
        displayName && (
          <div className=" bg-gray-light rounded border">
            <HeaderMenu items={headerMenuItems}>
              <div className="flex items-center hover:opacity-60 transition duration-150 ease-out">
                <DesignSystemAvatar src={avatarUrl} size="18rem" />{" "}
                <TextBody className="ml-[12rem]">{displayName}</TextBody>
              </div>
            </HeaderMenu>
          </div>
        )
      ) : (
        <Button
          className="py-3 px-6"
          theme="primary"
          onClick={(e) => {
            handleLogin(e);
          }}
          loading={isLoadingAuth}
        >
          {isLoadingAuth ? "Connecting" : "Connect Wallet"}
        </Button>
      )}
    </>
  );
};

export default HeaderAuthMenu;
