import { deleteReq, getReq, postReq, putReq } from "../ApiHelpers";

export interface KudosToken {
  tokenId: string;
  headline: string;
  imageUrl: string;
}
interface IBurnKudosParams {
  burningAddress: string;
  signature: string;
}

export interface KudosResponse {
  data: KudosToken[];
}
export interface HighlightedResponse {
  highlightedKudoses: KudosToken[];
}

export const getReceivedKudos = (username: string): Promise<KudosResponse> => {
  return getReq(`/identities/${username}/received`, undefined, false);
};

export const getSentKudos = (username: string): Promise<KudosResponse> => {
  return getReq(`/identities/${username}/sent`, undefined, false);
};

export const getClaimableKudos = (username: string): Promise<KudosResponse> => {
  return getReq(`/identities/${username}/claimable`, undefined, true);
};

export const createHighlightedKudos = (
  tokenId: string,
): Promise<KudosResponse> => {
  return postReq(`/token/${tokenId}/highlights`, null, undefined, true);
};

export const deleteHighlightedKudos = (
  tokenId: string,
): Promise<KudosResponse> => {
  return deleteReq(`/token/${tokenId}/highlights`, undefined, true);
};

export const postBurnKudos = (
  tokenId: string,
  params: IBurnKudosParams,
): Promise<KudosResponse> => {
  return postReq(`/token/${tokenId}/burn`, params, undefined, true, true);
};

export const updateProfile = (
  username: string,
  data: { username: string; discordAccounts: any[] },
) => {
  return putReq(`/identities/${username}`, data, undefined, true);
};

export const addRecipients = (
  tokenId: number,
  contributorsWithDisplayName: any,
  signature: string,
) => {
  return postReq(
    `/token/${tokenId}/addContributors`,
    {
      contributorsWithDisplayName: contributorsWithDisplayName,
      signature,
    },
    undefined,
    true,
    true,
  );
};

export const getUserIdentity = (username: string) => {
  return getReq(`/identities/${username}`, undefined, false);
};

export const getIdentityByAddress = (address: string) => {
  return getReq(`/identities?address=${address}`, undefined, false);
};
