import { cx, css } from "@emotion/css";
import { useEffect, useState } from "react";
import { getChainNameFromEnv } from "../../config/env";
import { useWallet } from "../../services/wallet/useWallet";
import { BASE_ASSET_URL } from "../../utils/asset";
import { TextBody } from "../DesignSystem/DesignSystemText";
import SwitchNetworkModal from "./SwitchNetworkModal";

const NetworkIndicator = () => {
  const { isCorrectNetwork } = useWallet();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen && isCorrectNetwork) {
      setIsModalOpen(false);
    }
  }, [isCorrectNetwork]);

  return (
    <>
      <button
        className={cx(
          isCorrectNetwork
            ? "bg-gray-light cursor-default border pointer-events-none"
            : "bg-[#df4146] hover:opacity-60 transition duration-150 ease-out",
          "py-2 px-4 rounded flex items-center justify-center gap-x-3",
        )}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {isCorrectNetwork ? (
          <img className={cx(fillBlue, "h-5 w-5")} src={polygonIconUrl} />
        ) : (
          <img className={cx("h-5 w-5", fillWhite)} src={networkIcon} />
        )}
        <TextBody className="">
          {isCorrectNetwork ? (
            getChainNameFromEnv()
          ) : (
            <span className="!text-white">Switch Network</span>
          )}
        </TextBody>
      </button>

      <SwitchNetworkModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

const polygonIconUrl = `${BASE_ASSET_URL}/icons/polygon.svg`;
const fillBlue = css`
  filter: invert(70%) sepia(99%) saturate(4761%) hue-rotate(212deg)
    brightness(82%) contrast(80%);
`;

const networkIcon = "/images/icons/network.svg";
const fillWhite = css`
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(5deg) brightness(102%)
    contrast(102%);
`; // #df4146

export default NetworkIndicator;
