import { TextLabel } from "../DesignSystem/DesignSystemText";

interface Props {
  label?: string;
  isRequired?: boolean;
  className?: string;
}

const FormLabel = ({ className, label, isRequired }: Props) => {
  return label ? (
    <TextLabel className={className}>
      {label} <span className="!text-red-500">{isRequired && "*"}</span>
    </TextLabel>
  ) : (
    <></>
  );
};

export default FormLabel;
