import { atom, useRecoilState } from "recoil";

/**
 * Returns whether the login screen is showing, and functions to change that.
 */
interface LoginScreenState {
  isOpen: boolean;
}
const LoginScreenAtom = atom<LoginScreenState>({
  key: "LoginScreen",
  default: {
    isOpen: false,
  },
});

// Open and close function
export function useLoginScreen() {
  const [state, setState] = useRecoilState(LoginScreenAtom);

  return {
    isOpen: state.isOpen,
    open() {
      setState({ isOpen: true });
    },
    close() {
      setState({ isOpen: false });
    },
  };
}
