import { postReq } from "../ApiHelpers";

interface ICreateKudosParams {
  creator: string;
  headline: string;
  description: string;
  startDateTimestamp: number;
  endDateTimestamp: number;
  communityId: string;
  links: string[];
  contributorsWithDisplayName: {
    accountDisplayId: string;
    accountUniqId: string;
  }[];
  isSignatureRequired: boolean;
  isAllowlistRequired: boolean;
  totalClaimCount: number;
  expirationTimestamp: number;
  signature: string;

  // temporary flag for ETH Denver
  TMP_imageType: string;
}

export const createKudos = (
  params: ICreateKudosParams,
): Promise<{ resourceId: string }> => {
  return postReq("/register", params, undefined, true, true);
};

interface CreateDiscordKudosParams {
  draftId: string;
  creator: string;
  signature: string;
  headline: string;
  description: string;
  startDateTimestamp: number;
  endDateTimestamp: number;
  communityId: string;
  links: string[];
  contributorsWithDisplayName: {
    accountDisplayId: string;
    accountUniqId: string;
  }[];
}

export const createDiscordKudos = (
  params: CreateDiscordKudosParams,
): Promise<{ resourceId: string }> => {
  return postReq("/register/discord", params, undefined, true, true);
};

export interface PreviewKudosParams {
  headline: string;
  description: string;
  communityUniqId: string;
  nftTypeId: string;
}

export const generatePreview = (
  params: PreviewKudosParams,
): Promise<{ previewAssetUrl: string }> => {
  return postReq("token/generatePreview", params, undefined, true);
};
