import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Button from "../../components/Button/Button";
import {
  TextBody,
  TextLink,
} from "../../components/DesignSystem/DesignSystemText";
import Form from "../../components/Form/Form";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import AccountModalBase from "./AccountModalBase";

const EmailLogin = ({ onClose, onBack, onSubmit, loggingIn }: any) => {
  const methods = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const { handleSubmit } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmitForm = handleSubmit(async (value: any) => {
    try {
      setLoading(true);
      await onSubmit(value.email);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw error;
    }
  });

  const walletFaqLink =
    "https://www.notion.so/contributionlabs/MintKudos-Docs-269191f781ff4708bc8ea5d10ef91f42#12969cc29bed48fbb1279b7fbf729fe9";

  return (
    <>
      <AccountModalBase
        title={!loggingIn ? "Sign in" : "Logging in..."}
        onClose={onClose}
      >
        {!loggingIn ? (
          <div className="min-w-[300rem]">
            <div className="mt-5 text-center max-w-[440rem]">
              <TextBody className="">
                If this is your first time logging in with this email, we’ll
                generate a new wallet for you using{" "}
                <TextLink href={"https://magic.link"} target="_blank">
                  magic.link
                </TextLink>
                . This is a separate account from your self-custody wallet.
                <br />
              </TextBody>
            </div>
            <div className="text-center mb-8 mt-3">
              <TextLink href={walletFaqLink} target="_blank">
                Click here to learn more.
              </TextLink>
            </div>

            {/* @ts-ignore */}
            <Form {...methods} onSubmit={onSubmitForm}>
              <div className="mb-7 min-w-[300rem]">
                <Form.Text
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                />
              </div>

              <div className="flex justify-between items-center">
                <Button onClick={onBack} theme="secondary">
                  &larr; Back
                </Button>
                <Button type="submit" theme="black" loading={loading}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <>
            <div className="mt-10 mb-7 flex items-center justify-center min-w-[300rem] min-h-[100rem]">
              <LoadingSpinner className="!w-14 !h-14"></LoadingSpinner>
            </div>
          </>
        )}
      </AccountModalBase>
    </>
  );
};

export default EmailLogin;
