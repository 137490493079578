import { getReq, postReq } from "../ApiHelpers";

export const loginWithConnAddr = async (connectorAddress: string) => {
  return postReq("/login/signature_message", {
    address: connectorAddress, //'0x10C95064D8e55b48dE787ABfCc6d6ac56D30Fe79'
  });
};

export const loginWithConnAddrAndSignature = (
  connectorAddress: string,
  signature: string,
  email?: string,
) => {
  return postReq("/login", {
    address: connectorAddress,
    signature: signature,
    email: email,
  });
};

export const getAuthenticatedAccount = () => {
  return getReq("/accounts/me", undefined, true);
};
