import { css, cx } from "@emotion/css";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import ErrorText from "../ErrorText";
import FormLabel from "../FormLabel";
import { borderStyle, fontStyle } from "../styles";

export interface DateFieldProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string;
  isRequired?: boolean;
  className?: string;
}

const DateField = ({
  name,
  label,
  isRequired,
  className,
  ...rest
}: DateFieldProps) => {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [name, unregister]);

  function update(input: HTMLInputElement) {
    if (input == null) return;
    input.dataset.value = input.value;
  }

  const reg = register(name);
  const { ref } = reg;

  return (
    <>
      <FormLabel label={label} isRequired={isRequired} />
      <div className="mt-2">
        <input
          type="date"
          className={cx(
            "h-13 w-full rounded px-5 relative",
            fontStyle,
            borderStyle(!!errors[name]),
            className,
            css`
              &[data-value=""]:not(:focus) {
                color: transparent;
              }

              &[data-value=""]:not(:focus)::after {
                content: attr(placeholder);
                position: absolute;
                left: 18rem;
                top: 2rem;
                bottom: 2rem;
                right: 20%;
                align-items: center;
                display: flex;
                color: #868e9988;
              }
            `,
          )}
          {...register(name)}
          ref={(input: any) => {
            update(input);
            return ref(input);
          }}
          {...rest}
        />
      </div>
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <ErrorText>{message}</ErrorText>}
      />
    </>
  );
};

export default DateField;
