import toast from "react-hot-toast";
import Button from "../components/Button/Button";

export const toastLink = (message: string, link: string) => {
  return toast.loading(
    <div className="flex items-center gap-x-5">
      <p>{message}</p>
      <a href={link} target="_blank">
        <Button className="max-h-8 rounded-[12rem]" theme="black">
          Link
        </Button>
      </a>
    </div>,
  );
};
