import { CheckIcon } from "@heroicons/react/solid";
import { ReactNode } from "react";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";

interface Props {
  children: ReactNode;
  loading?: boolean;
  complete?: boolean;
}

const AccountModalContainer = ({ children, loading, complete }: Props) => {
  return (
    <div className="bg-gray-light rounded px-5 py-3 h-[76rem] min-w-[320rem] flex items-center gap-x-5 whitespace-nowrap w-full">
      {children}
      {complete && <CheckIcon className="ml-auto w-6 h-6 text-[#6EC1AC]" />}
      {loading && <LoadingSpinner className="ml-auto w-6 h-6 !mr-0" />}
    </div>
  );
};

export default AccountModalContainer;
