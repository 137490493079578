import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import { featureFlag } from "../../config/featureFlag";

const Layout = ({ children }: any) => {
  return (
    <div className="z-0 flex flex-col h-screen">
      <Banner />
      <Header />
      {children}
    </div>
  );
};

export default Layout;
