import { postReq } from "../ApiHelpers";

interface IClaimKudosParams {
  claimingAddress: string;
  signature: string;
}

export const claimKudos = (
  tokenId: number,
  params: IClaimKudosParams,
): Promise<any> => {
  return postReq(`/token/${tokenId}/claim`, params, undefined, true, true);
};

interface ClaimDiscordKudosParams extends IClaimKudosParams {
  discordUserId: string;
}

export const claimDiscordKudos = (
  tokenId: number,
  params: ClaimDiscordKudosParams,
): Promise<any> => {
  return postReq(
    `/token/${tokenId}/claim/discord`,
    params,
    undefined,
    true,
    true,
  );
};

interface AirdropParams {
  address: string;
  captchaToken: string;
}

export const airdrop = (
  tokenId: number,
  params: AirdropParams,
): Promise<any> => {
  return postReq(`/token/${tokenId}/airdrop`, params, undefined, false, true);
};
