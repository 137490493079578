import { cx } from "@emotion/css";
import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/outline";
import {
  CogIcon,
  PhotographIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import React, { Fragment, ReactNode } from "react";
import { TextBody } from "../DesignSystem/DesignSystemText";

export interface HeaderMenuItemProps {
  icon: string;
  label: string;
  onClick: any;
}

const iconStyle: string = "h-5 w-5 mr-2";

const iconMap: Record<string, any> = {
  profile: <UserCircleIcon className={iconStyle} />,
  settings: <CogIcon className={iconStyle} />,
  claim: <PhotographIcon className={iconStyle} />,
  logout: <LogoutIcon className={iconStyle} />,
};

const HeaderMenuItem = ({ icon, label, onClick }: HeaderMenuItemProps) => {
  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={onClick}
            className={cx(
              active && "bg-[#f5f5f5]",
              "group flex w-full items-center rounded-[8px] px-2 py-2",
            )}
          >
            {iconMap[icon]}
            <TextBody>{label}</TextBody>
          </button>
        )}
      </Menu.Item>
    </>
  );
};

interface HeaderMenuProps {
  children: ReactNode;
  items: Array<HeaderMenuItemProps>;
}

const HeaderMenu = ({ children, items }: HeaderMenuProps) => {
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center bg-opacity-20 px-4 py-2 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 origin-top-right shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 min-w-[160px] rounded-[16px]">
            <div className="px-1 py-1">
              {items?.map((item) => {
                return (
                  item && (
                    <HeaderMenuItem
                      key={item.label}
                      icon={item.icon}
                      label={item.label}
                      onClick={item.onClick}
                    />
                  )
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default HeaderMenu;
