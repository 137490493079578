import { providers } from "ethers";

const Envs = ["staging", "sandbox", "production", "development"] as const;
export type Env = typeof Envs[number];

function getEnv(): Env {
  // @ts-ignore
  if (isOfTypeEnv(process.env.APP_ENV)) {
    return process.env.APP_ENV as Env;
  } else {
    return "development";
  }
}

function isOfTypeEnv(typeEnvCandidate: string): typeEnvCandidate is Env {
  return (Envs as readonly string[]).includes(typeEnvCandidate);
}

export const ETHEREUM_ALCHEMY_API_KEY = process.env.ETHEREUM_ALCHEMY_API_KEY!;
export const POLYGON_OR_MUMBAI_ALCHEMY_API_KEY =
  process.env.POLYGON_OR_MUMBAI_ALCHEMY_API_KEY!;
export const MAGIC_LINK_API_KEY = process.env.MAGIC_LINK_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY!;

export function getFrontendEndpoint(): string {
  if (AppEnv === "production") {
    return "https://mintkudos.xyz";
  } else if (AppEnv === "staging") {
    return "https://staging.mintkudos.xyz";
  } else if (AppEnv === "sandbox") {
    return "https://sandbox.mintkudos.xyz";
  } else {
    return "http://localhost:1234";
  }
}

export function getBackendEndpoint(): string {
  if (AppEnv === "production") {
    return "https://api.mintkudos.xyz";
  } else if (AppEnv === "staging") {
    return "https://staging-api.mintkudos.xyz";
  } else if (AppEnv === "sandbox") {
    return "https://sandbox-api.mintkudos.xyz";
  } else {
    return "http://localhost:8080";
  }
}

export function getRPCURL(): string {
  if (AppEnv === "development") {
    return "http://localhost:8545";
  } else {
    const network = AppEnv === "production" ? "matic" : "maticmum";
    const polygonAlchemyProvider = new providers.AlchemyProvider(
      network,
      POLYGON_OR_MUMBAI_ALCHEMY_API_KEY,
    );
    return polygonAlchemyProvider.connection.url;
  }
}

export function getChainIdFromEnv(): number {
  if (AppEnv === "production") {
    return 137;
  } else if (AppEnv === "staging" || AppEnv === "sandbox") {
    return 80001;
  } else {
    return 1337;
  }
}

export function getChainNameFromEnv(): string {
  if (AppEnv === "production") {
    return "Polygon";
  } else if (AppEnv === "staging" || AppEnv === "sandbox") {
    return "Mumbai";
  } else {
    return "Hardhat";
  }
}

export function getDiscordClientIdFromEnv(): string {
  if (AppEnv === "production") {
    return "937243873326039091";
  } else if (AppEnv === "staging") {
    return "966643407353176144";
  } else if (AppEnv === "sandbox") {
    return "985648130584371241";
  } else {
    return "980406695547260933";
  }
}

export function getChainExplorerUrl(): string {
  if (AppEnv === "production") {
    return "https://polygonscan.com";
  } else {
    return "https://mumbai.polygonscan.com";
  }
}

export const isTestnetEnv = () => {
  return AppEnv === "staging" || AppEnv === "sandbox";
};

export const AppEnv = getEnv();
