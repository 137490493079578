import { getChainNameFromEnv } from "../../config/env";
import { useWallet } from "../../services/wallet/useWallet";
import Button from "../Button/Button";
import { TextBody } from "../DesignSystem/DesignSystemText";
import Modal from "../Modal/Modal";

const SwitchNetworkModal = ({ open, onClose }: any) => {
  const { changeNetwork } = useWallet();

  return (
    <>
      <Modal open={open} onClose={onClose} showCloseButton>
        <div className="bg-white rounded-md overflow-hidden relative px-8 py-7 max-w-[360rem] text-center">
          <TextBody className="mb-8">
            Kudos is only supported on the {getChainNameFromEnv()} network.
            Please switch chains before proceeding.
          </TextBody>
          <Button
            theme="gray"
            className="!font-semibold !w-full"
            onClick={async () => {
              if (changeNetwork) {
                await changeNetwork();
              }
            }}
          >
            Switch to {getChainNameFromEnv()} Chain
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SwitchNetworkModal;
