import "../styles/globals.css";
import type { AppProps } from "next/app";
import Default from "../layouts/Default/Default";
import { RecoilRoot } from "recoil";
import { WagmiConfig } from "wagmi";
import { wagmiClient } from "../views/Account/AccountWagmiSetup";
import AccountLoginScreen from "../views/Account/AccountLoginScreen";
import { Toaster } from "react-hot-toast";
import { MagicProvider } from "../hooks/useMagicLink";
import Metadata from "../components/Metadata/Metadata";
import { BASE_ASSET_URL } from "../utils/asset";
import { UserProvider } from "../services/wallet/useWallet";
import Script from "next/script";
import { useEffect } from "react";
import { initFullStory } from "../services/monitoring/fullstory";

function MyApp({ Component, pageProps }: AppProps) {
  const toastOptions = {
    className: "",
    style: {
      border: "0.1rem solid rgba(134, 142, 153, 0.5)",
      boxShadow: "0px 8rem 16rem rgba(0, 0, 0, 0.15)",
      borderRadius: "20rem",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20rem",
      lineHeight: "24rem",
      maxWidth: "unset",
    },
  };

  useEffect(() => {
    initFullStory();
  }, []);

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-C2NTQS7759"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-C2NTQS7759');
        `}
      </Script>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-219983067-1"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-219983067-1');
        `}
      </Script>
      <Metadata
        title="Mint Kudos - Community contributions as soulbound tokens"
        description="Kudos are a way to turn individual and team off-chain contributions
    into on-chain, peer-verified, soulbound tokens with a celebratory twist! 😉"
        image={`${BASE_ASSET_URL}/branding/website-preview.png`}
      />

      <RecoilRoot>
        <WagmiConfig client={wagmiClient}>
          <MagicProvider>
            <UserProvider>
              <Default>
                <Component {...pageProps} />;
              </Default>
              <AccountLoginScreen />
            </UserProvider>
          </MagicProvider>
        </WagmiConfig>
      </RecoilRoot>
      <Toaster toastOptions={toastOptions} position="bottom-left" />
    </>
  );
}

export default MyApp;
