import { ethers } from "ethers";
import { Magic } from "magic-sdk";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  MAGIC_LINK_API_KEY,
  getRPCURL,
  getChainIdFromEnv,
} from "../config/env";

interface MagicContext {
  magic: any;
  magicProvider: any;
}

const Context = createContext<MagicContext>({
  magic: null,
  magicProvider: null,
});

export const MagicProvider = ({ children }: any) => {
  const [magic, setMagic] = useState<any>();
  const [magicProvider, setMagicProvider] = useState<any>();

  /**
   * Magic Link needs to be initialized within a useEffect because it accesses
   * the 'window' object, which isn't available initially when using SSR
   */
  useEffect(() => {
    if (!!magic && !!magicProvider) {
      return;
    }

    const m: any = new Magic(MAGIC_LINK_API_KEY ?? "", {
      network: {
        rpcUrl: getRPCURL(),
        chainId: getChainIdFromEnv(),
      },
    });
    const mProvider = new ethers.providers.Web3Provider(m.rpcProvider);

    setMagic(m);
    setMagicProvider(mProvider);
  }, []);

  return (
    <Context.Provider value={{ magic, magicProvider }}>
      {children}
    </Context.Provider>
  );
};

/**
 * Consumer for Magic Link object. Must be used within context of MagicProvider.
 * @returns { magic, magicProvider }
 */
export const useMagic = () => useContext(Context);
