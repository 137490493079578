import jwtDecode from "jwt-decode";
const JWT_TOKEN_KEY = "authJwtKey";

export const saveJwtAuthToken = (jwtToken: string) => {
  localStorage.setItem(JWT_TOKEN_KEY, jwtToken);
};

export const getJwtAuthToken = () => {
  const token = localStorage.getItem(JWT_TOKEN_KEY) || "";
  return token;
};

export const removeJwtAuthToken = () => {
  localStorage.removeItem(JWT_TOKEN_KEY);
};

const isExpired = (token: string) => {
  const { exp } = jwtDecode<{ exp: number; iat: number }>(token);
  const currentTime = new Date().getTime() / 1000;
  let isJwtExpired = false;
  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
};

export const isJwtTokenExpired = () => {
  try {
    const token = getJwtAuthToken();
    return isExpired(token);
  } catch (err) {
    return true;
  }
};
