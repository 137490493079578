import { isAddress } from "ethers/lib/utils";
import { alchemyProvider } from "../services/rpcProviders/rpc";

export async function getEnsAddress(ethAddress: string): Promise<string> {
  // Because the network that the user will be on is xDAI,
  // we won't be able to show the ENS by default. We need to make a special
  // call here to get the ENS value from the Ethereum mainnet
  // Ref: https://medium.com/the-ethereum-name-service/how-to-support-ens-for-multi-chain-dapps-b0a7ff043d77
  return (
    (ethAddress && (await alchemyProvider.lookupAddress(ethAddress))) ||
    Promise.resolve(ethAddress) // if there is no ENS mapping
  );
}

export async function getEthAddressFromEns(ensDomain: string): Promise<string> {
  // @ts-ignore
  return await alchemyProvider.resolveName(ensDomain);
}

export function isValid0xAddress(address: string): boolean {
  return isAddress(address);
}

export function isValidEnsString(address: string): boolean {
  return !!address.match(/^([\w.\-]+\.eth)$/i);
}

export async function mapAddressToContributor(address: string) {
  const invalidMessage = `${address} is not a valid address`;

  if (isValid0xAddress(address)) {
    return {
      accountUniqId: address,
      accountType: "wallet",
    };
  } else if (isValidEnsString(address)) {
    const ensDomain = await getEthAddressFromEns(address);
    if (!ensDomain) {
      throw new Error(invalidMessage);
    }
    return {
      accountDisplayId: address,
      accountUniqId: ensDomain,
      accountType: "wallet",
    };
  }

  throw new Error(invalidMessage);
}
