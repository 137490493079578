import { css, cx } from "@emotion/css";
import React, { useMemo } from "react";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme:
    | "primary"
    | "secondary"
    | "gray"
    | "white"
    | "custom"
    | "black"
    | "whiteBorder";
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  theme,
  className,
  disabled,
  type,
  loading,
  ...rest
}: ButtonProps) => {
  const isDisabledStyle: boolean = useMemo(() => {
    return (disabled || loading) ?? false;
  }, [disabled, loading]);

  const style = useMemo(() => {
    if (theme === "primary") {
      return isDisabledStyle ? deactivated : primary;
    } else if (theme === "secondary") {
      return secondary;
    } else if (theme === "gray") {
      return isDisabledStyle ? deactivatedGray : gray;
    } else if (theme === "white") {
      return isDisabledStyle ? deactivated : white;
    } else if (theme === "custom") {
      return isDisabledStyle ? "" : custom;
    } else if (theme === "black") {
      return isDisabledStyle ? deactivatedGray : black;
    } else if (theme === "whiteBorder") {
      return isDisabledStyle ? deactivatedGray : whiteBorder;
    }
  }, [theme, isDisabledStyle]);

  return (
    <button
      className={cx(className, style, !disabled ? transitionStyle : "")}
      type={type}
      disabled={isDisabledStyle}
      {...rest}
    >
      {loading && <LoadingSpinner className="w-2 h-2 mr-3" />}
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  theme: "primary",
};

const bodyFont = css`
  font-weight: 600;
  font-size: 17rem;
`;

const transitionStyle = "transition duration-150 ease-out";
const hoverStyle =
  "hover:scale-[1.03] hover:opacity-80 transition duration-150 ease-out";
const baseStyle =
  "py-2 px-4 rounded-lg min-w-[100rem] whitespace-nowrap flex items-center justify-center";

const primary = cx(
  "bg-gradient-to-r from-[#b9e5cd] to-blue-300 text-white shadow-button",
  baseStyle,
  hoverStyle,
  bodyFont,
);

const deactivated = cx(
  "bg-disabled text-white shadow-button",
  baseStyle,
  bodyFont,
);

const secondary = cx(
  "py-2 text-[#1a1a1a] hover:scale-105 opacity-50",
  bodyFont,
);

const gray = cx(
  "ease-out bg-[#DBDBDB66]/30 text-[17rem] font-medium",
  baseStyle,
  hoverStyle,
);

const deactivatedGray = cx(
  "ease-out bg-[#DBDBDB66]/50 text-[17rem] font-medium text-white",
  baseStyle,
);

const white = cx("bg-white shadow-button", baseStyle, hoverStyle, bodyFont);

const whiteBorder = cx(
  "bg-white border-solid border-[1.5px] border-black",
  baseStyle,
  hoverStyle,
  bodyFont,
);

const black = cx(
  "bg-black text-white shadow-button",
  baseStyle,
  hoverStyle,
  bodyFont,
);

const custom = cx(hoverStyle);

export default Button;
