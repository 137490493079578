import { cx } from "@emotion/css";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { ReactNode } from "react";
import Button from "../Button/Button";

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  actions?: ReactNode;
  showCloseButton?: boolean;
  className?: string;
}

const Modal = ({
  open,
  onClose,
  children,
  actions,
  showCloseButton,
  className,
}: Props) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className={cx(className, "w-full h-full z-10 relative")}
      >
        <div className="fixed inset-0  bg-[#00000099]" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel>
            <header className="absolute top-6 right-6 flex items-center gap-x-4">
              {actions}
              {showCloseButton && (
                <Button
                  theme="white"
                  className="h-9 w-9 px-[6rem] py-[6rem] min-w-0 rounded-[50%]"
                  onClick={onClose}
                >
                  <XIcon />
                </Button>
              )}
            </header>
            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
