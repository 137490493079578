import { cx } from "@emotion/css";
import { TextBody } from "../DesignSystem/DesignSystemText";

interface BannerProps {
  className?: string;
}

const Banner = ({ className }: BannerProps) => {
  return (
    <>
      <div className={cx(className, "w-full bg-[#6ec1ac]/30 p-6 text-center")}>
        <TextBody>
          We&apos;re sunsetting the MintKudos website on August 5th, 2023.
          Please read our announcement{" "}
          <a
            className="underline"
            target="_blank"
            href="https://mirror.xyz/mintkudosxyz.eth/WmeGeu5aCydHru3BPmTxzDv3AREicRTsNWAZJ1tlxDc"
            rel="noreferrer"
          >
            here
          </a>
          .
        </TextBody>
      </div>
    </>
  );
};

export default Banner;
