import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAccount, useConnect } from "wagmi";
import Button from "../../components/Button/Button";
import {
  TextBody,
  TextSecondary,
} from "../../components/DesignSystem/DesignSystemText";
import Modal from "../../components/Modal/Modal";
import { useWallet } from "../../services/wallet/useWallet";
import AccountModalBase from "./AccountModalBase";
import AccountModalContainer from "./AccountModalContainer";
import EmailLogin from "./EmailLogin";
import { useLoginScreen } from "./LoginScreenState";
import { useMagic } from "../../hooks/useMagicLink";
import Image from "../../components/Image/Image";

const CoinbaseWallet = "/images/icons/Coinbase Wallet.png";
const MetaMask = "/images/icons/MetaMask.png";
const WalletConnect = "/images/icons/WalletConnect.png";

const AccountLoginScreen: React.FC<{}> = (props) => {
  const { connectors, connectAsync, connect, isLoading, pendingConnector } =
    useConnect({
      onSettled() {
        // make sure provider value is synced with wallet state
        setProvider("wagmi");
      },
      async onError(error) {
        // fully disconnect and allow user to retry if there's an error
        if (account?.status === "connected") {
          await disconnect();
          toast.error("Please reconnect your wallet and try again");
        }
      },
    });
  const loginScreen = useLoginScreen();
  const {
    loginMetaMask,
    loginMagicLink,
    isLoadingAuth,
    provider,
    disconnect,
    setProvider,
  } = useWallet();
  const account = useAccount();
  const [loggingInEmail, setLoggingInEmail] = useState(false);
  const router = useRouter();
  const { magic, magicProvider } = useMagic();

  const onWalletConnection = async (connector: any) => {
    if (connector?.name === "WalletConnect") {
      loginScreen.close();
    }
    try {
      await connectAsync({ connector: connector });
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const magicLogin = async (email: any) => {
    try {
      await magic.auth.loginWithMagicLink({ email });
      setLoggingInEmail(true);
      const signer = magicProvider.getSigner();
      const address = await signer.getAddress();
      await loginMagicLink(address, email);
      setLoggingInEmail(false);
      setIsEmail(false);
      loginScreen.close();
    } catch (error: any) {
      setLoggingInEmail(false);
      toast.error(error.message || error);
      throw error;
    }
  };

  const searchParams = router.query ?? {};
  const redirectUrl = searchParams?.redirect || "";

  const redirectToOldUrl = (path: string) => {
    router.push(path);
  };

  const [isEmail, setIsEmail] = useState(false);

  useEffect(() => {
    async function fetchJWTToken() {
      if (account.status !== "connected") {
        return;
      }
      try {
        if (provider === "wagmi") {
          await loginMetaMask(account?.address);
          loginScreen.close();
          if (redirectUrl) {
            redirectToOldUrl(redirectUrl as string);
          }
        }
      } catch (error: any) {
        // When a signature is denied, sometimes wagmi will throw a non human-readable
        // error as a list of numbers (ex. 87,101,108,99...). This catches that case using
        // the "ACTION_REJECTED" code that's returned and displays the correct message that
        // should be shown from a 4001 (UserDeniedSignatureError).
        if (error.code?.length > 0 && error.code.includes("ACTION_REJECTED")) {
          toast.error("User rejected request");
        } else {
          toast.error(error.message);
        }
      }
    }
    fetchJWTToken();
  }, [account.status, provider, account?.address]);

  if (!loginScreen.isOpen) {
    return null;
  }

  const mapConnectorToImage = (connectorId: string) => {
    if (connectorId === "injected") {
      return MetaMask;
    } else if (connectorId === "walletConnect") {
      return WalletConnect;
    } else if (connectorId === "coinbaseWallet") {
      return CoinbaseWallet;
    } else {
      return MetaMask;
    }
  };

  return (
    <Modal open={loginScreen.isOpen} onClose={loginScreen.close}>
      {isEmail ? (
        <EmailLogin
          onSubmit={magicLogin}
          onClose={() => {
            setIsEmail(false);
            loginScreen.close;
          }}
          onBack={() => {
            setIsEmail(false);
          }}
          loggingIn={loggingInEmail}
        />
      ) : (
        <AccountModalBase title="Sign in" onClose={loginScreen.close}>
          <div className="flex flex-col gap-y-[10rem] mb-6 mt-7">
            {connectors.map((connector, index) => (
              <Button
                key={`account-login-button-${index}`}
                theme="custom"
                disabled={isLoading || isLoadingAuth}
                onClick={() => {
                  onWalletConnection(connector);
                }}
              >
                <AccountModalContainer
                  loading={
                    pendingConnector?.id === connector.id &&
                    (isLoading || isLoadingAuth)
                  }
                >
                  <Image
                    height="32rem"
                    width="32rem"
                    src={mapConnectorToImage(connector.id)}
                    alt=""
                  />
                  <TextBody>
                    {connector.id === "injected" ? "MetaMask" : connector.name}
                    {!connector.ready && " (unsupported)"}
                  </TextBody>
                </AccountModalContainer>
              </Button>
            ))}

            <Button
              theme="custom"
              disabled={isLoading || isLoadingAuth}
              onClick={() => {
                setIsEmail(true);
              }}
              className="mb-[-12rem] mt-5"
            >
              <TextSecondary>Login with email</TextSecondary>
            </Button>
          </div>
        </AccountModalBase>
      )}
    </Modal>
  );
};

export default AccountLoginScreen;
