import { providers } from "ethers";
import { configureChains, createClient, defaultChains } from "wagmi";
import { localhost, polygon, polygonMumbai } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { alchemyProvider as WagmiAlchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

// Import alchemy key from ENV
const apiKey = process.env.POLYGON_OR_MUMBAI_ALCHEMY_API_KEY;

// Configure chains & providers with the Alchemy provider.
let providerList = !!apiKey
  ? [WagmiAlchemyProvider({ alchemyId: apiKey }), publicProvider()]
  : [publicProvider()];

const { chains, provider, webSocketProvider } = configureChains(
  [...defaultChains, localhost, polygon, polygonMumbai],
  providerList,
);

// Set up client
export const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    // Metamask connector
    // Note: the shimDisconnect flag is important: https://github.com/tmm/wagmi/pull/119
    // Note: the shimChainChangedDisconnect flag is a temp workaround due to a MM issue: https://github.com/MetaMask/metamask-extension/issues/13375#issuecomment-1046125113
    // - TLDR is that MM emits a disconnect event when switching to a non-default chain.
    // - Despite the confusing variable name, based on the wagmi source code, the library ignores the Metamask disconnect event when `shimChainChangedDisconnect=true`
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});
