import * as FullStory from "@fullstory/browser";
import { AppEnv } from "../../config/env";

export function initFullStory(): void {
  if (AppEnv === "production") {
    FullStory.init({ debug: false, orgId: "18M9FM" });
  }
}

export function identifyUserInFullStory(address: string): void {
  if (AppEnv === "production") {
    FullStory.identify(`${address}`);
  }
}

export function anonymizeUserInFullStory(): void {
  if (AppEnv === "production") {
    FullStory.anonymize();
  }
}
