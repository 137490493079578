import { atom, useRecoilState } from "recoil";
import {
  isJwtTokenExpired,
  removeJwtAuthToken,
  saveJwtAuthToken,
} from "./Auth";

const jwtAuthState = atom<{ isAuthenticated: boolean }>({
  key: "JwtAuthAtom",
  default: {
    isAuthenticated: !isJwtTokenExpired(),
  },
});

export const useJwtAuth = () => {
  const [jwtState, setJwtState] = useRecoilState(jwtAuthState);

  const saveJwtToken = (token: string) => {
    saveJwtAuthToken(token);
    setJwtState({
      isAuthenticated: true,
    });
  };

  const removeJwt = () => {
    removeJwtAuthToken();
    setJwtState({
      isAuthenticated: false,
    });
  };

  return {
    saveJwtToken,
    removeJwt,
    ...jwtState,
  };
};
