import { css, cx } from "@emotion/css";
import { useEffect, useMemo, useState } from "react";
import BrandingText from "../../assets/Branding/BrandingText";
import { getClaimableKudos, KudosToken } from "../../services/apis";
import { useWallet } from "../../services/wallet/useWallet";
import { TextBody, TextH3 } from "../DesignSystem/DesignSystemText";
import HeaderNavLink from "./HeaderNavLink";
import Image from "../Image/Image";
import { isTestnetEnv } from "../../config/env";
import HeaderAuthMenu from "./HeaderAuthMenu";
import NetworkIndicator from "./NetworkIndicator";

const Header: React.FC = (props) => {
  const { isLoggedIn } = useWallet();

  const showLoggedOut = useMemo(() => {
    return !isLoggedIn;
  }, [isLoggedIn]);

  const LoggedOutHeader = () => {
    return (
      <>
        <HeaderNavLink
          theme="secondary"
          target="_blank"
          href={BrandingText.DiscordInviteUrl}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 436px) {
                width: 10px;
              }
            `}
          >
            <Image
              src="/images/branding/icon/IconDiscord.svg"
              width="18rem"
              height="18rem"
              alt=""
            />
          </div>
          <span
            className={css`
              @media (max-width: 436px) {
                display: none;
              }
            `}
          >
            Discord
          </span>
        </HeaderNavLink>
        <HeaderNavLink
          theme="secondary"
          target="_blank"
          href={BrandingText.TwitterUrl}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 436px) {
                width: 10px;
              }
            `}
          >
            <Image
              src="/images/branding/icon/IconTwitter.svg"
              width="18rem"
              height="18rem"
              alt=""
            />
          </div>
          <span
            className={css`
              @media (max-width: 436px) {
                display: none;
              }
            `}
          >
            Twitter
          </span>
        </HeaderNavLink>
      </>
    );
  };

  const LoggedInHeader = () => {
    return (
      <>
        <HeaderNavLink theme="secondary" to="/create">
          Send Kudos
        </HeaderNavLink>
      </>
    );
  };

  return (
    <header
      className={css`
        label: Header;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 120px 30px;
        width: 100%;
        @media (max-width: 768px) {
          padding: 32px 20rem 29.5px 20rem;
        }
        @media (max-width: 436px) {
          padding: 20px 20rem 10px 20rem;
        }
      `}
    >
      <nav
        className={cx(
          css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10rem clamp(5rem, 5vw - 10rem, 50rem);
            min-height: 40px;
          `,
        )}
      >
        <HeaderNavLink theme="secondary" to="/">
          <p
            className={css`
              label: Header-left;
              font-size: 47px !important;
              margin-right: 12rem;
              @media (max-width: 768px) {
                font-size: 25px !important;
              }
            `}
          >
            🎉
          </p>
          {isTestnetEnv() && (
            <TextH3 className={gradientTextStyle}>Testnet</TextH3>
          )}
        </HeaderNavLink>
        <HeaderNavLink
          theme="secondary"
          target="_blank"
          href={BrandingText.DocumentationUrl}
        >
          Guide
        </HeaderNavLink>
        <HeaderNavLink
          theme="secondary"
          target="_blank"
          href={BrandingText.DevelopersUrl}
        >
          API Docs
        </HeaderNavLink>
        {showLoggedOut ? (
          <LoggedOutHeader key="loggedOut" />
        ) : (
          <LoggedInHeader key="loggedIn" />
        )}
      </nav>

      <nav
        className={css`
          label: Header-right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 20rem;
          min-height: 40px;
          flex-wrap: wrap-reverse;
        `}
      >
        {/* Display socials if logged out, display create link if logged in */}
        <>
          {isLoggedIn && <NetworkIndicator />}
          <HeaderAuthMenu />
        </>
      </nav>
    </header>
  );
};

const gradientTextStyle = css`
  background-image: linear-gradient(to right, #6ec1ac, #739cff);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export default Header;
