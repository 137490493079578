export const assetIsVideo = (assetUrl: string) => {
  if (!assetUrl) {
    return false;
  }

  const extension = assetUrl?.split(".")?.pop();
  return extension === "mp4";
};

export const assetIsGif = (assetUrl: string) => {
  if (!assetUrl || typeof assetUrl !== "string") {
    return false;
  }

  const extension = ((assetUrl as string) ?? "")?.split(".")?.pop() ?? "";
  return extension === "gif";
};

export const BASE_ASSET_URL = "https://images.mintkudos.xyz/frontend";
