import React from "react";
import { css, cx } from "@emotion/css";
import DesignSystemButton from "../DesignSystem/DesignSystemButton";
import DesignSystemText from "../DesignSystem/DesignSystemText";
import { Router, useRouter } from "next/router";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: "secondary" | "primary";
  to?: string;
}

const styles = css`
  label: HeaderNavLink;
  ${DesignSystemText.AppHeaderStyle};

  border-radius: 5em;
  border: 2px solid transparent;

  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.5em;
  transition: opacity 0.15s ease-out;
  &:hover {
    opacity: 0.6;
  }
  white-space: nowrap;
`;

const HeaderNavLink: React.FC<Props> = (props: any) => {
  const { theme, target, href, children } = props;

  const router = useRouter();

  if (props.to) {
    return (
      <a
        target={target}
        className={cx(theme, styles)}
        onClick={() => {
          if (props.to === "/create" && router.route.includes("/create")) {
            router.reload();
          } else {
            router.push(props.to);
          }
        }}
      >
        {children}
      </a>
    );
  } else if (theme === "primary") {
    return (
      <DesignSystemButton theme="primary" target={target} href={href}>
        {children}
      </DesignSystemButton>
    );
  } else {
    return (
      <a target={target} href={href} className={cx(theme, styles)}>
        {children}
      </a>
    );
  }
};

export default HeaderNavLink;
