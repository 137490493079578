import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { getBackendEndpoint, getChainExplorerUrl } from "../../config/env";
import { ErrorResponse } from "../../utils/error";
import { toastLink } from "../../utils/toast";
import { getJwtAuthToken } from "../auth/Auth";
import ApiInstance from "./ApiInstance";

/**
 * consume an api Promise. If status is less than 300 assume success and return data; If status > 300 assume error and reject with error data
 * @param api
 */
const callApiAndReturnData = async (api: Promise<AxiosResponse>) => {
  try {
    const response = await api;
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError;

    return Promise.reject(
      new ErrorResponse(
        getErrorMessage(axiosError),
        axiosError.response ? axiosError.response?.status : null,
        axiosError.response?.data?.name ?? undefined,
      ),
    );
  }
};

const getOperationResult = async (operationUrl: string) => {
  let status = "pending" as "success" | "pending" | "processing" | "error";
  const maxTimeoutInMs = 120000;
  const sleepInMs = 500;
  let curTimeoutInMs = 0;

  let toastId = "";
  let curTxHash = "";
  try {
    while (status === "pending" || status === "processing") {
      const operationRes = await ApiInstance.get(
        `${getBackendEndpoint()}${operationUrl}`,
      );

      const txHash = operationRes.data?.txHash || "";
      if (txHash !== curTxHash) {
        // update toast
        toast.dismiss(toastId);
        const explorerUrl = getChainExplorerUrl();
        const link = `${explorerUrl}/tx/${txHash}`;
        toastId = toastLink("Transaction pending...", link);
        curTxHash = txHash;
      }

      status = operationRes.data["status"];
      if (status === "success") {
        if (toastId) {
          toast.dismiss(toastId);
        }
        return operationRes.data;
      } else if (status === "error") {
        if (toastId) {
          toast.dismiss(toastId);
        }
        return Promise.reject(
          new ErrorResponse("Operation failed", 500, "OperationFailed"),
        );
      }

      curTimeoutInMs += sleepInMs;
      if (curTimeoutInMs >= maxTimeoutInMs) {
        if (toastId) {
          toast.dismiss(toastId);
        }
        return Promise.reject(
          new ErrorResponse(
            "Operation polling timed out",
            500,
            "OperationPollTimedOut",
          ),
        );
      }

      // sleep for 500ms
      await new Promise((f) => setTimeout(f, sleepInMs));
    }
  } catch (error) {
    if (toastId) {
      toast.dismiss(toastId);
    }
    throw error;
  }
};

const pollForOperationResultAndReturnData = async (
  api: Promise<AxiosResponse>,
) => {
  try {
    const response = await api;
    const operationUrl = response.headers["location"];
    if (!operationUrl) {
      return Promise.reject(
        new ErrorResponse(
          "operationUrl does not exist in response's Location header",
          500,
          "NoOperationUrl",
        ),
      );
    }
    return await getOperationResult(operationUrl);
  } catch (err) {
    const axiosError = err as AxiosError;

    // for conflicts with a location header, we should poll for the operation result
    if (
      axiosError?.response?.status === 409 &&
      axiosError?.response.headers["location"]
    ) {
      return await getOperationResult(axiosError?.response.headers["location"]);
    }

    return Promise.reject(
      new ErrorResponse(
        getErrorMessage(axiosError),
        axiosError.response ? axiosError.response?.status : null,
        axiosError.response?.data?.name ?? undefined,
      ),
    );
  }
};

const getErrorMessage = (axiosError: AxiosError) => {
  let errorMessage = axiosError.message;
  if (
    axiosError.response?.status === 500 &&
    axiosError.response.headers["mintkudos-correlation-id"]
  ) {
    const correlationId =
      axiosError.response.headers["mintkudos-correlation-id"];
    errorMessage = `Internal error, please contact us in the #support channel on Discord with the correlationId: ${correlationId}.`;
  } else if (
    axiosError.response?.data &&
    Object?.keys(axiosError.response?.data).includes("error")
  ) {
    errorMessage = axiosError.response?.data["error"];
  }
  return errorMessage;
};

const addAuthTokenToConfig = (
  config: AxiosRequestConfig | null | undefined,
) => {
  const token = getJwtAuthToken() || "";
  config = config || {};

  config.headers = {
    Authorization: `Bearer ${token}`,
  };

  return config;
};

export const postReq = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig | undefined,
  authenticate?: boolean,
  pollForOperationResult?: boolean,
) => {
  if (authenticate) {
    config = addAuthTokenToConfig(config || {});
  }
  if (pollForOperationResult) {
    return pollForOperationResultAndReturnData(
      ApiInstance.post(url, data, config),
    );
  } else {
    return callApiAndReturnData(ApiInstance.post(url, data, config));
  }
};

export const getReq = (
  url: string,
  config?: AxiosRequestConfig | undefined,
  authenticate?: boolean,
) => {
  if (authenticate) {
    config = addAuthTokenToConfig(config);
  }

  return callApiAndReturnData(ApiInstance.get(url, config));
};

export const patchReq = (
  url: string,
  data: any,
  config?: AxiosRequestConfig | undefined,
  authenticate?: boolean,
) => {
  if (authenticate) {
    config = addAuthTokenToConfig(config || {});
  }

  return callApiAndReturnData(ApiInstance.patch(url, data, config));
};

export const putReq = (
  url: string,
  data: any,
  config?: AxiosRequestConfig | undefined,
  authenticate?: boolean,
) => {
  if (authenticate) {
    config = addAuthTokenToConfig(config || {});
  }

  return callApiAndReturnData(ApiInstance.put(url, data, config));
};

export const deleteReq = (
  url: string,
  config?: AxiosRequestConfig | undefined,
  authenticate?: boolean,
) => {
  if (authenticate) {
    config = addAuthTokenToConfig(config || {});
  }

  return callApiAndReturnData(ApiInstance.delete(url, config));
};
