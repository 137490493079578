import {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useContext,
  createContext,
  useState,
} from "react";
import toast from "react-hot-toast";
import { atom, AtomEffect, DefaultValue, useRecoilState } from "recoil";
import {
  useAccount,
  useDisconnect,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from "wagmi";
import { Address } from "../../@types/Address";
import { DiscordAccount } from "../../@types/DiscordAccount";
import { Role } from "../../@types/Role";
import { getChainIdFromEnv, getChainNameFromEnv } from "../../config/env";
import { useMagic } from "../../hooks/useMagicLink";
import { getEnsAddress } from "../../utils/addressUtils";
import { truncateAddress } from "../../utils/string";
import { getClaimableKudos, IKudosDetails } from "../apis";
import {
  getAuthenticatedAccount,
  loginWithConnAddr,
  loginWithConnAddrAndSignature,
} from "../apis/AccountApi";
import { deleteDiscordAccount } from "../apis/AccountApi/Discord";
import { getRequestsForIdentity } from "../apis/KudosApi/RequestKudos";
import { isJwtTokenExpired } from "../auth/Auth";
import { useJwtAuth } from "../auth/JwtAuthState";
import {
  anonymizeUserInFullStory,
  identifyUserInFullStory,
} from "../monitoring/fullstory";

export interface WalletState {
  identityId: string;
  username: string;
  address?: string;
  addresses: Address[];
  avatarUrl: string;
  displayName: string;
  shortAddress: string;
  discordLoggedIn: boolean;
  discordAccounts: DiscordAccount[];
  roles: Role[];
  featureFlags: any;
}

const DEFAULT_USER_DATA: WalletState = {
  identityId: "",
  username: "",
  addresses: [
    {
      address: "",
    },
  ],
  avatarUrl: "/images/icons/DefaultUser.png",
  displayName: "",
  shortAddress: "",
  discordLoggedIn: false,
  discordAccounts: [],
  roles: [
    {
      communityUniqId: "",
      role: "",
    },
  ],
  featureFlags: {},
};

const WalletAtom = atom<WalletState>({
  key: "Wallet",
  default: DEFAULT_USER_DATA,
});

const isLoadingAtom = atom<boolean>({
  key: "isLoading",
  default: false,
});

type WalletProvider = "wagmi" | "magic";

const providerAtom = atom<WalletProvider>({
  key: "provider",
  default: "wagmi",
});

const Context = createContext<any>(null);

// Global wallet account hook
export const UserProvider = ({ children }: any) => {
  const [state, setWalletState] = useRecoilState(WalletAtom);
  const [isLoadingAuth, setIsLoadingAuth] = useRecoilState(isLoadingAtom);
  const [provider, setProvider] = useRecoilState(providerAtom);
  const [claimable, setClaimable] = useState<IKudosDetails[] | null>(null);
  const [requests, setRequests] = useState<any[] | null>(null);

  const accountData = useAccount();
  const { magic, magicProvider } = useMagic();

  /**
   * Sets walletState and syncs it with localStorage.
   */
  const setState = useCallback(
    (newValue: WalletState) => {
      setWalletState(newValue);
      localStorage?.setItem("walletState", JSON.stringify(newValue));
    },
    [setWalletState],
  );

  useEffect(() => {
    // Initialize user data from localStorage
    const savedValue = localStorage?.getItem("walletState");
    if (savedValue != null) {
      setState(JSON.parse(savedValue));
    }
  }, [setState]);

  // We use useRef so that a function within this hook (loginMetaMask)
  // can reference the latest state value. We need to do this because
  // the atom effect changes the state value on its own, but when the
  // loginMetaMask function starts, the state value does not have those
  // changes yet.
  // Reference: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const walletRef = useRef<WalletState>();
  walletRef.current = state;

  const jwtAuth = useJwtAuth();
  const disconnectHook = useDisconnect();
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage({
    onError(error) {
      setIsLoadingAuth(false);
      throw error;
    },
  });

  /**
   * Stores the current network ID that the user's wallet is connected to.
   * We need to store this separate from wagmi because the "chain" state
   * from the useNetwork hook does not react to manual network switches.
   */
  const [chainId, setChainId] = useState<number | undefined>(chain?.id);

  useEffect(() => {
    if (chain?.id) {
      setChainId(chain.id);
    }
  }, [chain]);

  const isLoggedIn = useMemo(() => {
    return jwtAuth.isAuthenticated && !!state.username;
  }, [jwtAuth.isAuthenticated, state]);

  const disconnect = async () => {
    // first remove JWT token
    // Note: there is no /logout endpoint in the backend but once we add it
    // we would call that here too
    jwtAuth.removeJwt();

    // then remove listener to wagmi connector before disconnecting Metamask
    // Note: sometimes the connector may already be disconnected, but we still
    // want to clean up and disconnect fully
    if (accountData?.connector) {
      accountData.connector.removeListener("change");
      accountData.connector.removeListener("disconnect");
    }

    // then call the Metamask disconnect
    if (provider === "wagmi") {
      await disconnectHook?.disconnectAsync();
    } else {
      await magic?.user?.logout();
      setProvider("wagmi");
    }

    // set local state to default again
    setState({
      ...state,
      ...DEFAULT_USER_DATA,
    });

    // disassociate user from logged-in user
    anonymizeUserInFullStory();
  };

  const changeNetwork = async () => {
    const requiredChainId = getChainIdFromEnv();
    const chainName = getChainNameFromEnv();
    const switchErrorMessage = `Please switch networks to ${chainName} before proceeding`;

    try {
      if (chainId === requiredChainId) {
        return;
      }

      // it's possible for some wallet connections to not have switchNetwork capabilities
      if (switchNetworkAsync === undefined) {
        throw new Error(switchErrorMessage);
      } else {
        // Note that the chain value from the useNetwork hook does not update
        // immediately - might be something wrong with the wagmi library.
        // Because of this issue, we don't actually check whether the chain.id != chainId.
        // Instead, we just try to switch network everytime just in case (because
        // there won't be a switch network pop-up if the chain is correct).
        await switchNetworkAsync(requiredChainId);
      }
    } catch (error: any) {
      // Handles the error case when the switch network prompt is already open.
      if (error.code === 4902) {
        throw new Error(switchErrorMessage);
      } else {
        throw error;
      }
    }
  };

  const updateUsername = (newUsername: string) => {
    setState({
      ...state,
      username: newUsername,
    });
  };

  const initializeAccountInfo = async () => {
    const authenticatedAccount = await getAuthenticatedAccount();
    const mainAddressObject = authenticatedAccount?.addresses[0] ?? {};
    const ensName = await getEnsAddress(mainAddressObject.address);
    const newEmail = mainAddressObject.email;
    const shortAddress = mainAddressObject.address
      ? truncateAddress(mainAddressObject.address)
      : "";

    const displayName = ensName.includes(".eth")
      ? `${ensName}`
      : newEmail
      ? newEmail
      : shortAddress;

    setState({
      ...walletRef.current,
      ...authenticatedAccount,
      displayName,
      shortAddress,
    });
    setIsLoadingAuth(false);
  };

  const loginMetaMask = async (connectorAddress: string) => {
    try {
      setIsLoadingAuth(true);

      if (!isJwtTokenExpired() && !isLoggedIn) {
        await initializeAccountInfo();
        setIsLoadingAuth(false);
        return;
      }

      // if already logged in then return early
      if (state?.username && accountData?.address && !isJwtTokenExpired()) {
        console.log("already logged in");
        setIsLoadingAuth(false);
        return;
      }

      const messageRes = await loginWithConnAddr(connectorAddress);
      const message = messageRes.message;

      const signature = await signMessageAsync({ message });

      const loginRes = await loginWithConnAddrAndSignature(
        connectorAddress,
        signature,
      );

      if (loginRes) {
        const JWTToken = { token: loginRes.token, address: connectorAddress };
        jwtAuth.saveJwtToken(JWTToken.token);

        await initializeAccountInfo();
        identifyUserInFullStory(connectorAddress);
      }

      setIsLoadingAuth(false);
    } catch (err) {
      // We don't want to actually connect the wallet, so call disconnect
      await disconnect();
      setIsLoadingAuth(false);
      throw err;
    }
  };

  const loginMagicLink = async (connectorAddress: string, email: string) => {
    try {
      // if already logged in then return early
      if (state?.username && !isJwtTokenExpired()) {
        console.log("already logged in");
        return;
      }

      const messageRes = await loginWithConnAddr(connectorAddress);
      const message = messageRes.message;

      // Sign in and set provider as 'magic'. Any errors beyond this step will be handled
      // by the catch, which will clean up and reset the provider to the default 'wagmi'
      const signer = magicProvider.getSigner();
      const signature = await signer.signMessage(message);
      setProvider("magic");

      const loginRes = await loginWithConnAddrAndSignature(
        connectorAddress,
        signature,
        email,
      );
      if (loginRes) {
        const JWTToken = { token: loginRes.token, address: connectorAddress };
        jwtAuth.saveJwtToken(JWTToken.token);

        // check discord logged in state
        const authenticatedAccount = await getAuthenticatedAccount();
        const mainAddressObject = authenticatedAccount?.addresses[0] ?? {};
        const shortAddress = mainAddressObject.address
          ? truncateAddress(mainAddressObject.address)
          : "";

        setState({
          ...walletRef.current,
          ...authenticatedAccount,
          shortAddress: shortAddress,
          displayName: mainAddressObject.email,
        });

        setIsLoadingAuth(false);
      }
    } catch (err) {
      // We don't want to actually connect the wallet, so call disconnect
      await disconnect();
      setIsLoadingAuth(false);
      throw err;
    }
  };

  // Set WalletAtom state whenever wagmi account changes
  useEffect(() => {
    async function updateWalletAtom() {
      const address = accountData?.address;

      if (address && isLoggedIn) {
        const shortAddress = address ? truncateAddress(address) : "";
        const ensAddress = await getEnsAddress(address);
        const ensDomain = ensAddress.includes(".eth") ? ensAddress : "";
        setState({
          ...state,
          addresses: [{ address, ensDomain }],
          shortAddress: shortAddress ?? "",
        });
      }
    }

    updateWalletAtom();
  }, [accountData?.address, isLoggedIn]);

  useEffect(() => {
    async function setProviderAndMaybeDisconnect() {
      // We only disconnect if and only if the following conditions are met:
      // 1. wagmi status is disconnected
      // 2. user is not logged-in with magic.link
      //
      // Note: This hook solves the issue of Metamask getting disconnected in the background without
      // emitting any active disconnect events.
      // Ref: https://github.com/tmm/wagmi/issues/444#issuecomment-1123862297

      // Try to exit early so we don't call the magic endpoint too unnecessarily
      // as they can rate-limit us.
      if (!jwtAuth.isAuthenticated) {
        return;
      }
      if (["connecting", "reconnecting"].includes(accountData?.status)) {
        return;
      }
      if (accountData?.status === "connected") {
        setProvider("wagmi");
        return;
      }
      const isMagicLoggedIn = await magic?.user?.isLoggedIn();
      if (isMagicLoggedIn) {
        setProvider("magic");
        return;
      }

      await disconnect();
    }
    setProviderAndMaybeDisconnect();
  }, [accountData?.status]);

  useEffect(() => {
    if (!(accountData?.status === "connected") || !accountData.connector) {
      return;
    }

    // reset
    accountData.connector.removeListener("change");
    accountData.connector.removeListener("disconnect");

    // Start listening to the disconnect event (only injected)
    // Note: sometimes the wallet may disconnect on its own due to
    // expiry or the user manually hitting disconnect from website.
    // We should be reactive to those changes.
    // Note: we do this outside any of the funcs b/c we want to
    // make sure whenever the useWallet hook is instantiated we are
    // listening to these events.
    if (accountData.connector.listeners("change").length === 0) {
      accountData.connector.on("change", async (connectorData) => {
        // This makes sure out chainId is always in sync with the wallet state.
        setChainId(connectorData?.chain?.id);

        // we don't want to disconnect if the connected chain is changed
        // (we force the user to be back on the correct network on any action anyway)
        if (
          connectorData.account &&
          // make sure stored address is not empty AND the address is not the same
          walletRef?.current?.addresses[0].address !== "" &&
          walletRef?.current?.addresses[0].address !== connectorData.account
        ) {
          await disconnect();
        }
      });
    }
    if (accountData.connector.listeners("disconnect").length === 0) {
      accountData.connector.on("disconnect", disconnect);
    }
  }, [accountData?.status]);

  const setDiscordAccounts = (discordAccounts: DiscordAccount[]) => {
    setState({ ...state, discordAccounts });
  };

  const removeDiscordAccount = async () => {
    if (!state.discordAccounts.length) {
      return;
    }

    await deleteDiscordAccount(state.discordAccounts[0].discordUserId);
    setDiscordAccounts({
      ...state,
      //@ts-ignore
      discordAccounts: [],
    });
  };

  const getUpdatedAccount = async () => {
    const authenticatedAccount = await getAuthenticatedAccount();
    setState({
      ...walletRef.current,
      ...authenticatedAccount,
    });
  };

  const fetchClaimable = async () => {
    let claimable: any = [];
    claimable = await getClaimableKudos(state.username);
    setClaimable(claimable?.data);
    return claimable;
  };

  useEffect(() => {
    const fetch = async () => {
      if (!isLoggedIn) {
        return;
      }
      await fetchClaimable();
    };

    fetch();
  }, [isLoggedIn]);

  const isCorrectNetwork = useMemo(() => {
    return provider === "magic" || chainId === getChainIdFromEnv();
  }, [chainId, provider]);

  /**
   * Checks if feature flag is enabled
   * @param flag String from AWS AppConfig
   * @returns true if enabled
   */
  const isFeatureEnabled = (
    flag: "accessCommunityPage" | "requestKudos",
  ): boolean => {
    return !!state.featureFlags[flag];
  };

  const value = {
    disconnect,
    loginMetaMask,
    loginMagicLink,
    changeNetwork,
    updateUsername,
    ...state,
    isLoadingAuth,
    provider,
    isLoggedIn,
    setDiscordAccounts,
    discordLogIn() {
      setState({ ...state, discordLoggedIn: true });
    },
    removeDiscordAccount,
    getUpdatedAccount,
    setProvider,
    claimable,
    fetchClaimable,
    requests,
    chainId,
    isCorrectNetwork,
    isFeatureEnabled,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useWallet = () => useContext(Context);
