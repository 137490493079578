/* eslint-disable react/no-children-prop */
import { css, cx } from "@emotion/css";
import { HTMLAttributeAnchorTarget } from "react";

const DesignSystemText = {
  /**
   * @deprecated Use `TextH1` instead.
   */
  H1: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(
          css`
            label: DesignSystemText-H1;
            color: #1a1a1a;
            font-weight: 800;
            font-size: 48rem;
            font-family: Inter, sans-serif;
            margin: 0;
          `,
          props.className,
        )}
      />
    );
  },

  /**
   * @deprecated Use `TextH2` instead.
   */
  H2: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(
          css`
            label: DesignSystemText-H2;
            color: #1a1a1a;
            font-weight: 800;
            font-size: 40rem;
            font-family: Inter, sans-serif;
            margin: 0;

            @media (max-width: 350px) {
              font-size: 14px !important;
            }
          `,
          props.className,
        )}
      />
    );
  },

  /**
   * @deprecated Use `TextH3` instead.
   */
  H3: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(
          css`
            label: DesignSystemText-H3;
            color: #1a1a1a;
            font-size: 24rem;
            font-weight: 600;
            font-family: Inter, sans-serif;
          `,
          props.className,
        )}
      />
    );
  },

  /**
   * @deprecated Use `TextSecondary` instead.
   */
  Secondary: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(
          css`
            label: DesignSystemText-H4;
            color: #1a1a1a80;
            font-size: 17rem;
            font-weight: 400;
            font-family: Inter, sans-serif;
            white-space: pre-wrap;
          `,
          props.className,
        )}
      />
    );
  },

  /**
   * @deprecated Use `TextLink` instead.
   */
  Link: (
    props: React.PropsWithChildren<{
      href: string;
      target?: HTMLAttributeAnchorTarget;
      className?: string;
    }>,
  ) => {
    return (
      <a
        children={props.children}
        target={props.target}
        href={props.href}
        className={cx(
          css`
            label: DesignSystemText-Link;
            color: #1a1a1a80;
            font-size: 17rem;
            font-weight: 400;
            font-family: Inter, sans-serif;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              color: #1a1a1a;
            }
          `,
          props.className,
        )}
      />
    );
  },

  LabelStyle: css`
    label: DesignSystemText-LabelStyle;
    color: #1a1a1a;
    font-size: 17rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  `,
  /**
   * @deprecated Use `TextLabel` instead.
   */
  Label: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(DesignSystemText.LabelStyle, props.className)}
      />
    );
  },

  BodyStyle: css`
    label: DesignSystemText-BodyStyle;
    color: #1a1a1a;
    font-size: 17rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  `,
  /**
   * @deprecated Use `TextBody` instead.
   */
  Body: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(DesignSystemText.BodyStyle, props.className)}
      />
    );
  },

  AppHeaderStyle: css`
    label: DesignSystemText-AppHeaderStyle;
    font-size: 16rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  `,
  AppHeader: (props: React.PropsWithChildren<{ className?: string }>) => {
    return (
      <div
        children={props.children}
        className={cx(DesignSystemText.AppHeaderStyle, props.className)}
      />
    );
  },
};

export const TextH1 = DesignSystemText.H1;
export const TextH2 = DesignSystemText.H2;
export const TextH3 = DesignSystemText.H3;
export const TextSecondary = DesignSystemText.Secondary;
export const TextLink = DesignSystemText.Link;
export const TextLabel = DesignSystemText.Label;
export const TextBody = DesignSystemText.Body;

export default DesignSystemText;
