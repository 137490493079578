import { deleteReq, postReq } from "../ApiHelpers";

export const postDiscordAuthCode = async (authCode: string) => {
  return postReq(
    "/accounts/discord_oauth",
    {
      authCode: authCode,
    },
    undefined,
    true,
  );
};

export const deleteDiscordAccount = async (accountUniqId: string) => {
  return deleteReq(`/accounts/me/${accountUniqId}`, undefined, true);
};
