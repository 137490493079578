import NextImage, { ImageProps as NextImageProps } from "next/image";
import { useState } from "react";
import { assetIsGif } from "../../utils/asset";

type Image = NextImageProps;

const Image = (props: NextImageProps) => {
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      {error || assetIsGif((props.src as string) ?? "") ? (
        <img
          src={(props.src as "") ?? ""}
          alt={props.alt}
          height={props.height}
          width={props.width}
        />
      ) : (
        <NextImage
          onError={(error: any) => {
            setError(error);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default Image;
