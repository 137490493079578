import Head from "next/head";
import Script from "next/script";
import { assetIsVideo, BASE_ASSET_URL } from "../../utils/asset";

interface Props {
  title?: string;
  image?: string;
  description?: string;
  tabTitle?: string;
}

const defaultMeta = {
  title: "Mint Kudos - Community contributions as soulbound tokens",
  tabTitle: "Kudos",
  image: `${BASE_ASSET_URL}/branding/website-preview.png`,
};

const Metadata = ({ title, description, image, tabTitle }: Props) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta property="og:url" content="https://mintkudos.xyz" key="url" />
      <title>{tabTitle || defaultMeta.tabTitle}</title>
      <meta
        property="og:title"
        content={`🎉 ${title ?? defaultMeta.title}`}
        key="title"
      />
      <meta
        property="og:image"
        content={
          image && assetIsVideo(image)
            ? defaultMeta.image
            : image ?? defaultMeta.image
        }
        key="image"
      />
      <meta property="og:description" content={description} key="description" />
    </Head>
  );
};

export default Metadata;
