import { cx } from "@emotion/css";
import { ReactNode } from "react";
import { TextBody } from "../DesignSystem/DesignSystemText";

interface Props {
  children: ReactNode;
  className?: string;
}

const ErrorText = ({ children, className }: Props) => {
  return (
    <>
      <TextBody className={cx("mt-2 !text-[#df4146] !text-[12px]", className)}>
        {children}
      </TextBody>
    </>
  );
};

export default ErrorText;
