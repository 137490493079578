import React from "react";
import { css, cx } from "@emotion/css";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: "hidden" | "text" | "secondary" | "primary" | [string, string];
  active?: boolean;
  circular?: boolean;
  large?: boolean;
}

const DesignSystemButton: React.FC<Props> = (props) => {
  const { active, circular, large, theme, ...buttonProps } = props;

  const colorsStyle =
    theme instanceof Array &&
    css`
      background: ${theme[0]};
      color: ${theme[1]};
    `;

  return (
    <a
      draggable="false"
      {...buttonProps}
      className={cx(
        typeof theme === "string" && theme,
        { active, circular, large },
        css`
          label: DesignSystemButton;
          font: 600 17rem/1.3em Inter, sans-serif;

          &.large {
            font: 400 24rem/1.3em Inter, sans-serif;
          }

          /*
           * The "0 0 0 0.1px #88888855" shadow is a hack to get rid of a very
           * thin white line than sometimes appears. I don't know why it
           * works, I just played around with css until the problem got fixed.
           * Test after making modifications.
           * https://discord.com/channels/917259207739318362/938884964689252392/938891970376499222
           */
          box-shadow: 0 0 0 0.1px #88888855, 0 5rem 11rem 0 #88888855;
          border-radius: 5em;
          border: 2px solid transparent;
          padding: 0.6em 2em;
          cursor: pointer;
          white-space: nowrap;
          text-decoration: none;

          &.circular {
            padding: 0.6em;
          }

          transition: opacity 0.2s ease-out, transform 0.2s ease-out;

          &:hover {
            /* https://stackoverflow.com/a/52768926/782045 */
            transform: scale(1.05) translate3d(0, 0, 0.1px) perspective(0.1px);
          }

          &:active,
          &.active {
            opacity: 0.5;
            border-color: #1a1a1a40;
          }

          display: inline-flex;
          align-items: center;

          &.hidden {
            visibility: hidden;
          }
          &.secondary {
            background: white;
            color: #1a1a1a;
          }
          &.primary {
            background: border-box linear-gradient(to right, #b9e5cd, #b7d4f7);
            color: white;
          }
          &.text {
            background: transparent;
            box-shadow: none;
            margin: 0 -1em;
            color: #1a1a1a80;
          }

          ${colorsStyle}
        `,
        props.className,
      )}
    />
  );
};

export default DesignSystemButton;
