import { css, cx } from "@emotion/css";
import Image from "../Image/Image";

const DesignSystemAvatar: React.FC<{
  className?: Parameters<typeof cx>[0];
  size: string;
  src?: string;
  background?: string;
  children?: undefined;
  radius?: string;
}> = (props) => {
  let style = css`
    label: DesignSystemAvatar;
    width: ${props.size};
    height: ${props.size};
    background: ${props.background ?? "unset"};
    border-radius: ${props.radius ?? "50%"};
  `;

  return props.src == null ? (
    <div
      className={cx(
        css`
          label: DesignSystemAvatar;
          width: ${props.size};
          height: ${props.size};
          background: ${props.background ?? "unset"};
          border-radius: ${props.radius ?? "50%"};
        `,
        props.className,
      )}
    ></div>
  ) : (
    <Image
      src={props.src}
      className={cx(
        css`
          label: DesignSystemAvatar;
          width: ${props.size};
          height: ${props.size};
          background: ${props.background ?? "unset"};
          border-radius: ${props.radius ?? "50%"};
        `,
        props.className,
      )}
      height={props.size}
      width={props.size}
      alt="Avatar"
      priority
      loading="eager"
    />
  );
};

export default DesignSystemAvatar;
